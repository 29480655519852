import camelcaseKeys from 'camelcase-keys';
import { differenceBy, isArray, isEmpty, isEqual, iteratee } from 'lodash';
import { differenceInMinutes } from 'date-fns';
import logger from './logger';
import { getHostEnv } from './api';

export const camelizeObjects = (array) => {
  if (isArray(array)) {
    const newArray = [];
    array.forEach((e) => {
      newArray.push(camelcaseKeys(e));
    });
    return newArray;
  }
  console.error('[camelizeObjects] Invalid input array.');
  return null;
};

export const imageLoader = ({ src, width, quality }) =>
  // return `https://example.com/${src}?w=${width}&q=${quality || 75}`;
  src;

export const defaultConstraints = {
  audio: {
    autoGainControl: false,
    channelCount: 2,
    echoCancellation: true,
    noiseSuppression: true,
    sampleRate: 48000,
    sampleSize: 16,
  },
  video: {
    facingMode: 'user',
    focusMode: 'manual',
    width: { ideal: 1280 },
    height: { ideal: 720 },
    frameRate: { ideal: 15 },
    // focusDistance: '0.1',
    // focusDistance: '10',
    // ...additionalSizeConstraint,
  },
};

export const hasUserMedia = () => {
  const nav: any = navigator;
  nav.getUserMedia =
    nav.getUserMedia ||
    nav.webkitGetUserMedia ||
    nav.mozGetUserMedia ||
    nav.msGetUserMedia ||
    nav.mediaDevices.getUserMedia; // latest spec
  return !!nav.getUserMedia;
};

export const isCameraMicAndPermissionOk = async () => {
  const isPermissionOk = true;
  let isVideoMicOk = false;

  if (hasUserMedia()) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        defaultConstraints
      );

      if (stream) isVideoMicOk = true;
    } catch (error) {
      await logger.error(error);
      alert('An error occurred while trying to get user video/mic feeds');
    }
  } else {
    await logger.error('hasUserMedia() error');
    alert('An error occurred while trying to get user video/mic feeds');
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
  // Firefox has limited support
  // try {
  //   const cameraResult = await navigator.permissions.query({ name: 'camera' });
  //   const isCameraAccessGranted = cameraResult.state !== 'denied';
  //   if (!isCameraAccessGranted) isPermissionOk = false;
  //
  //   const microphoneResult = await navigator.permissions.query({
  //     name: 'microphone',
  //   });
  //   const isMicrophoneAccessGranted = microphoneResult.state !== 'denied';
  //   if (!isMicrophoneAccessGranted) isPermissionOk = false;
  // } catch (e) {
  //   await logger.error(e);
  // }

  return isPermissionOk && isVideoMicOk;
};

export const getMediaStream = async (constraints) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    const videoTrack = stream.getVideoTracks()[0];
    const existingConstraints = videoTrack.getConstraints();
    await videoTrack.applyConstraints(existingConstraints);
    await logger.info(JSON.stringify(videoTrack.getConstraints()));

    const capabilities = videoTrack.getCapabilities
      ? videoTrack.getCapabilities()
      : null;
    const settings = videoTrack.getSettings();

    return stream;
  } catch (err) {
    await logger.error(err);
  }

  return false;
};

export const isAppointmentValid = (appointment: any) => {
  const { hostname } = window.location;
  if (appointment?.startDate instanceof Date) {
    const now = new Date();
    const minutesToStart = differenceInMinutes(appointment?.startDate, now);
    let meetingDuration = differenceInMinutes(
      appointment?.endDate,
      appointment?.startDate
    );
    if (meetingDuration > 60) {
      meetingDuration = 60;
      logger
        .warn(
          `Appointment ${JSON.stringify(
            appointment
          )} duration is LONGER than 60 minutes!`
        )
        .catch();
    }
    if (getHostEnv() !== 'prod') {
      return true;
    }

    if (
      (minutesToStart > 0 && minutesToStart <= 10) ||
      (minutesToStart <= 0 && Math.abs(minutesToStart) < meetingDuration + 5)
    ) {
      return true;
    }
  }
  return false;
};

export const fetchDelayInMs = 500;

export const filterTopics = (topics: any, user: any) => {
  const superUserDoNotFilterAnyProviders = ['christan'];
  let currentUser = '';
  if (!isEmpty(user)) {
    currentUser = `${user.givenName}${user.familyName}`;
  }
  if (superUserDoNotFilterAnyProviders.includes(currentUser)) {
    return topics;
  }
  const testAccountsList = [
    { profileBannerUrl: '/christan' },
    { profileBannerUrl: '/cooeechief' },
    { profileBannerUrl: '/harrypotter' },
    { profileBannerUrl: '/susantan' },
    { profileBannerUrl: '/christan1' },
  ];
  const filteredTopics = differenceBy(
    topics,
    testAccountsList,
    'profileBannerUrl'
  );
  return filteredTopics;
};

const hasEmpty = (arr) => isEqual(arr, [{}]);

export const isEmptyObject = (obj) => isEqual(obj, {});
