import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import urlcat from 'urlcat';
import camelcaseKeys from 'camelcase-keys';
import { isBoolean, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import logger from '../utils/logger';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';
import axios from '../utils/axios';
import { setShowLoginBox, updateUserInfo } from '../store/actions/appActions';
import { isEmptyObject } from '../utils/common';

const useUserInfo = () => {
  // @ts-ignore
  const { app } = useSelector((state) => state);
  const { isAuthenticated, user, userInfo } = app;
  const dispatch = useDispatch();
  const maxVerifyCount = 6;
  const [isLoading, setIsLoading] = useState(false);
  const [verifyCount, setVerifyCount] = useState(0);
  const [isVerifyingPhoneChange, setIsVerifyingPhoneChange] = useState(false);
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const memoizedUserInfo = useMemo(() => camelcaseKeys(userInfo), [userInfo]);

  const isUserAuthenticated = () => isAuthenticated;

  const handleAuthentication = () => {
    if (!isAuthenticated) {
      dispatch(setShowLoginBox(true));
      setShowLoginBox(true);
      // Clear user
    }
  };

  const refreshUserInfo = async () => {
    if (!isLoading && !isEmptyObject(userInfo)) {
      setIsLoading(true);
      let response: AxiosResponse;
      try {
        response = await axios.get(urlcat(baseUrl, 'user/info'));
        const { data, status } = response;
        if (status === 200) {
          setIsLoading(false);
          dispatch(
            updateUserInfo({
              ...camelcaseKeys(data?.data),
              lastUpdate: new Date(),
            })
          );
        } else await logger.warn('Error getting user info').finally();
      } catch (error) {
        await logger.error(error);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    const isPhoneVerified = async () => {
      const { phoneVerified } = memoizedUserInfo;
      if (isBoolean(phoneVerified) && phoneVerified) {
        setIsVerifyingPhoneChange(false);
      }
    };

    (async () => {
      if (
        !isEmpty(memoizedUserInfo) &&
        isVerifyingPhoneChange &&
        verifyCount <= maxVerifyCount
      ) {
        await isPhoneVerified();
        setIsVerifyingPhoneChange(false);

        setTimeout(
          () => {
            refreshUserInfo().catch();
            setIsVerifyingPhoneChange(true);
          },
          verifyCount <= 1 ? 10000 : 20000
        );
        setVerifyCount(verifyCount + 1);
      } else {
        setIsVerifyingPhoneChange(false);
      }
    })();
  }, [isVerifyingPhoneChange]);

  const startVerifyPhoneChange = () => {
    refreshUserInfo().catch(); // Causes the warning alert to display
    setVerifyCount(0);
    setTimeout(() => setIsVerifyingPhoneChange(true), 20000);
  };

  if (differenceInMinutes(new Date(), memoizedUserInfo?.lastUpdaste) > 5) {
    refreshUserInfo().catch();
    // } else if (
    //   isBoolean(memoizedUserInfo.emailVerified) &&
    //   !memoizedUserInfo.emailVerified
    // ) {
    //   console.log('hi2');
    //   startVerifyEmailChange();
  }

  return {
    handleAuthentication,
    isUserAuthenticated,
    user,
    userInfo: memoizedUserInfo,
    startVerifyPhoneChange,
    getUserInfo: refreshUserInfo,
    isAuthenticated,
    refreshUserInfo,
  };
};

export default useUserInfo;
